<template>
  <div>
    <v-row>
      <v-col sm="12" class="text-right">
        <v-btn icon outlined color="primary" :to="{name: 'add-celebration'}"><v-icon v-html="icons.mdiPlus"/></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title><h4>Celebration List</h4></v-card-title>
          <v-card-text>
            <v-row>
              <v-col :md="$store.state.me && $store.state.me.role === 'admin' ? 4 : 6">
                <v-text-field
                  dense
                  outlined
                  clearable
                  label="Search"
                  :prepend-inner-icon="icons.mdiMagnify"
                  class="app-bar-search flex-grow-0"
                  :value="selectedFilters.search"
                  @input="debounceSearch"
                />
              </v-col>
              <v-col v-if="$store.state.me && $store.state.me.role === 'admin'" :md="$store.state.me.role === 'admin' ? 4 : 6">
                <v-select
                  outlined
                  dense
                  chips
                  deletable-chips
                  clearable
                  multiple
                  label="Temples"
                  placeholder="Temples"
                  item-text="name"
                  item-value="id"
                  :items="templeOptions"
                  :value="selectedFilters.temples"
                  @change="updateFilter($event,'temples')"
                />
              </v-col>
              <v-col :md="$store.state.me && $store.state.me.role === 'admin' ? 4 : 6">
                <v-select
                  outlined
                  dense
                  chips
                  deletable-chips
                  clearable
                  multiple
                  label="Festivals"
                  placeholder="Festivals"
                  item-text="name"
                  item-value="id"
                  :items="festivalOptions"
                  :value="selectedFilters.festivals"
                  @change="updateFilter($event,'festivals')"
                />
              </v-col>
              <v-col md="4" v-if="false">
                <v-btn outlined class="mr-2"><v-icon>{{ icons.mdiDownload }}</v-icon>Export PDF</v-btn>
                <v-btn outlined><v-icon>{{ icons.mdiDownload }}</v-icon>Export Excel</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="celebrations"
                  disable-sort
                  :hide-default-footer="!celebrations.length"
                  :items-per-page="15"
                  :page="pagination.page"
                  :server-items-length="pagination.total"
                  :footer-props="{
                    'items-per-page-options': [15],
                    'items-per-page-text': '',
                  }"
                  @update:page="handleUpdatePage"
                  :loading="loading"
                >
                  <template v-slot:item.donations_sum_amount="{item}">
                    <span>₹{{item.donations_sum_amount ? item.donations_sum_amount : 0}}</span>
                  </template>
                  <template v-slot:item.created_at="{item}">
                    <span>{{item.created_at | moment('DD/MM/YYYY')}}</span>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-if="false">
                          <v-list-item-title>New Donation</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="false">
                          <v-list-item-title>Donation List</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{name: 'edit-celebration', params: {id: item.id}}">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmDeletion(item.id)" class="error">
                          <v-list-item-title class="white--text">Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent max-width="400" :value="deleteForm">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteForm = false">No</v-btn>
          <v-btn color="primary" @click="deleteCelebration">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mdiPlus, mdiDotsVertical, mdiMagnify} from '@mdi/js';
import _ from "lodash";

export default {
  data() {
    return {
      celebrations: [],
      queryParams: {
        page: 1,
        search: null,
        temples: [],
        festivals: [],
      },
      pagination: {
        page: 1,
        total: 0,
      },
      festivalOptions: [],
      templeOptions: [],
      deletingCelebrationID: null,
      deleteForm: false,
      headers: [
        {text: 'Name', align: 'start', value: 'name'},
        {text: 'Festival', align: 'start', value: 'festival.name'},
        {text: 'Temple', align: 'start', value: 'temple.name'},
        {text: 'Collected', value: 'donations_sum_amount'},
        {text: 'Date', value: 'created_at'},
        {text: 'Actions', value: 'action', align: 'end'},
      ],
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiDotsVertical
      },
      loading: false,
    }
  },
  computed: {
    selectedFilters() {

      return  {
        search: this.queryParams.search,
        temples: this.queryParams.temples,
        festivals: this.queryParams.festivals,
      };
    }
  },
  watch: {
    queryParams: {
      deep: true,
      async handler() {

        if (this.$router.currentRoute.fullPath !== this.$router.resolve({name: this.$router.currentRoute.name, query: this.queryParams}).route.fullPath) this.$router.replace({query: this.queryParams});
        await this.fetchCelebrations(this.queryParams);
      }
    },
    deleteForm() {

      if (this.deleteForm === false) this.deletingCelebrationID = null;
    }
  },
  created() {

    axiosBackend.get(`/options/festivals`).then(async (response) => {

      this.festivalOptions = response.data.festivals;

      axiosBackend.get(`/options/temples`).then(async (response) => {

        this.templeOptions = response.data.temples;

        if (Object.entries(this.$router.currentRoute.query).length > 0) {

          this.queryParams = {
            page: this.$router.currentRoute.query.page && this.$router.currentRoute.query.page !== 'undefined' ? parseInt(this.$router.currentRoute.query.page) : 1,
            search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
            temples: this.$router.currentRoute.query.temples ? (Array.isArray(this.$router.currentRoute.query.temples) ? this.$router.currentRoute.query.temples.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.temples)]) : [],
            festivals: this.$router.currentRoute.query.festivals ? (Array.isArray(this.$router.currentRoute.query.festivals) ? this.$router.currentRoute.query.festivals.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.festivals)]) : [],
          };
        } else {

          this.fetchCelebrations(this.queryParams);
        }
      });
    });
  },
  methods: {
    fetchCelebrations(params) {

      this.loading = true;
      axiosBackend.get(`/celebrations`, {params}).then(async (response) => {

        this.celebrations = response.data.celebrations;
        this.pagination.total = response.data.meta.total;
        this.pagination.page = response.data.meta.current_page;
        this.loading = false;
      });
    },
    deleteCelebration() {

      this.loading = true;
      axiosBackend.delete('/celebrations/' + this.deletingCelebrationID)
        .then(() => {

          this.fetchCelebrations(this.queryParams);
          this.deleteForm = false;
          this.loading = false;
        })
        .catch();
    },
    debounceSearch: _.debounce(function (value) {

      this.updateFilter(value, 'search');
    }, 500),
    updateFilter(value, field) {

      this.queryParams[field] = value;
      this.queryParams.page = 1;
    },
    handleUpdatePage(page) {

      this.queryParams.page = page;
    },
    confirmDeletion(id) {

      this.deletingCelebrationID = id;
      this.deleteForm = true;
    }
  }
}
</script>
